import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useForm from "../hooks/useForm";
import { updateAnuncio, addAnuncio } from "../store/actions/anuncioActions";
import { fetchConToken } from "../utils/fetch";
import Modal from "./Modal";
import Input from "./UI/forms/Input";
import InputFile from "./UI/forms/InputFile";
import Select from "./UI/forms/Select";

const initialState = {
  titulo: "",
  hora: "",
  fecha: 1,
  lugar: "",
  comentario: "",
  comentarioSolicitud: "",
  aprobado: 0,
};

const AnuncioModal = ({
  open = null,
  setOpen = null,
  selected,
  button = null,
}) => {
  const [open2, setOpen2] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [form, inputChange, setForm] = useForm(initialState);
  const {
    titulo,
    hora,
    fecha,
    lugar,
    comentario,
    comentarioSolicitud,
    aprobado,
  } = form;

  const loadData = useCallback(async () => {
    try {
      const result = await fetchConToken("anuncio/" + selected).then(r =>r.json());
      setForm(result.anuncio);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const sendData = () => {
    setLoading(true);
    try {
      delete form.afiche;

      if (selected) {
        fetchConToken(
          `anuncio/${selected}`,
          form,
          "post",
          true
        ).then((r) => r.json())
        .then(result =>{
          result.ok ? update(result.anuncio) : errors();
        })

      } else {
        fetchConToken(
          "anuncio",
          { ...form },
          "post",
          true
        ).then((r) => r.json())
        .then(result =>{
          result.ok ? register(result.anuncio) : errors();
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const register = ({ id, titulo, hora, fecha, lugar, aprobado }) => {
    dispatch(
      addAnuncio({ id, titulo, hora, fecha, lugar, aprobado })
    );
    handleClose();
  };

  const update = (data) => {
    dispatch(updateAnuncio(data));
    handleClose();
  };

  const handleClose = () => {
    setLoading(false);
    setForm(initialState);
    open !== null ? setOpen(false) : setOpen2(false);
  };

  const errors = () => {};

  useEffect(() => {
    if (selected && open === true) {
      setLoading(true);
      loadData();
    } else {
      setLoading(false);
    }
  }, [open]);

  return (
    <>
      <Modal
        open={open ? open : open2}
        setOpen={setOpen}
        title={selected ? "Detalles anuncio" : "Agregar anuncio"}
        onSubmit={sendData}
        loanding={loading}
        handleClose={handleClose}
      >
        <Input
          title="Titulo"
          value={titulo}
          name="titulo"
          onChange={inputChange}
        />
        <div className="grid grid-cols-2 gap-1">
          <Input
            title="Hora (24h)"
            value={hora}
            name="hora"
            onChange={inputChange}
            type="time"
          />

          <Input
            title="Fecha"
            value={fecha}
            name="fecha"
            onChange={inputChange}
            type="date"
          />
        </div>
        <Input
          title="Lugar"
          value={lugar}
          name="lugar"
          onChange={inputChange}
        />
        <Input
          title="Comentario público"
          value={comentario}
          name="comentario"
          onChange={inputChange}
        />
        <Input
          title="Comentario para secretaria"
          value={comentarioSolicitud}
          name="comentarioSolicitud"
          onChange={inputChange}
        />
        <Select
          options={[
            { key: true, value: "Aprobado" },
            { key: false, value: "No aprobado" },
          ]}
          title="Aprobado"
          name="aprobado"
          value={aprobado}
          onChange={inputChange}
        />
      </Modal>
      {button && <div onClick={() => setOpen2(true)}>{button}</div>}
    </>
  );
};

export default AnuncioModal;
