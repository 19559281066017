
import {combineReducers} from 'redux';
import authReducer from './authReducer';
import modalReducer from './modalReducer';
import navbarReducer from './navbarReducer';
import activityReducer from './activityReducer';
import anuncioReducer from './anuncioReducer';
import memberReducer from './memberReducer';
import scheduleReducer from './scheduleReducer';
import dashboardReducer from './dashboardReducer';

export const rootReducer = combineReducers({
    authReducer,
    modalReducer,
    navbar: navbarReducer,
    activities: activityReducer,
    anuncios: anuncioReducer,
    members: memberReducer,
    schedules: scheduleReducer,
    dashboard: dashboardReducer,
})