import { dashboardType } from '../types/dashboardType';

export const loadDashboard = (data) => ({
  type: dashboardType.load,
  payload: data,
});

export const updateMsg = (msg) => ({
    type: dashboardType.updateMsg,
    payload: msg,
  });

export const removeSlope = (id, type) => ({
  type: dashboardType.removeSlope,
  payload: {id, type}
})
