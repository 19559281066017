import { useEffect } from "react";
import { Provider } from "react-redux";

import Navigation from "./components/Navigation";
import { store } from "./store";

function App() {

  useEffect(()=>{
    const dark = parseInt(localStorage.getItem('darkMode'));
    dark && document.documentElement.classList.add('dark');
  },[])

  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  );
}

export default App;
