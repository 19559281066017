import { dashboardType } from "../types/dashboardType"

const initialState = {
  data: {
      statistics:[],
      slopes:[],
      msg:{id:null,message:""}
    },
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardType.load:
      return {
        ...state,
        data: action.payload,
      };
      case dashboardType.updateMsg:
        return {
          ...state,
          data: {...state.data,msg:action.payload},
        };
    case dashboardType.removeSlope:
      return {
        ...state,
        data: {
            ...state.data,
            slopes:state.data.slopes.filter((e) => e.id !== action.payload.id)
        },
      };
    default:
      return state;
  }
};

export default dashboardReducer;
