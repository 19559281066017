import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BtnRounded = ({ icon, color, id, onClick = () => {} }) => {
  return (
    <button
      onClick={() => onClick(id)}
      className={`flex items-center justify-center border rounded-full p-1 border-gray-400 h-[30px] w-[30px] hover:bg-${color}-500`}
    >
      {icon ? (
        <FontAwesomeIcon icon={icon} color={color} />
      ) : (
        <p className="text-red-500 font-bold transition-colors duration-300 hover:text-white">X</p>
      )}
    </button>
  );
};

export default BtnRounded;
