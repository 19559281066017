import React, { useState } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import BtnRounded from "./UI/BtnRounded";
import Modalconfirm from "./Modalconfirm";
import { fetchConToken } from "../utils/fetch";
import { useDispatch } from "react-redux";
import { removeSlope } from "../store/actions/dashboardActions";

const Aprobacion = ({data}) => {
  const dispatch = useDispatch();
  const [openModalApro, setOpenModalApro] = useState(false);
  const [loadingApro, setLoadingApro] = useState(false);
  const [openModalRem, setOpenModalRem] = useState(false);
  const [loadingRem, setLoadingRem] = useState(false);

  const aprobar = ()=>{
    setLoadingApro(true)
    if (data.type == 'A') {
      fetchConToken('activity/'+data.id,{aprobado:1},"post")
      .then(r=>r.json())
      .then(r=>{
        if (r.ok == 'ok') {
        } 
        setOpenModalApro(false);
        dispatch(removeSlope(data.id, data.type))
      })
    }else{
        fetchConToken('anuncio/'+data.id,{aprobado:1},"post")
        .then(r=>r.json())
        .then(r=>{
          if (r.ok == 'ok') {
          } 
          setOpenModalApro(false);
          dispatch(removeSlope(data.id, data.type))
        })
    }
  }

  const deleteSlope = ()=>{
    setLoadingRem(true)
    if (data.type == 'A') {
      fetchConToken('activity/'+data.id,{},"delete")
      .then(r=>r.json())
      .then(r=>{
        if (r.ok == 'ok') {
        } 
        setOpenModalApro(false);
        dispatch(removeSlope(data.id, data.type))
      })
    }else{
        fetchConToken('anuncio/'+data.id,{},"delete")
        .then(r=>r.json())
        .then(r=>{
          if (r.ok == 'ok') {
          } 
          setOpenModalApro(false);
          dispatch(removeSlope(data.id, data.type))
        })
    }
  }

  return (
    <tr className="bg-gray-100 my-3 border-b-2 border-b-white dark:border-b-gray-300 dark:bg-gray-200">
      <td className="p-2">{data.titulo}</td>
      <td className="p-2">{data.type}</td>
      <td className="p-2">{data.comentarioSolicitud}</td>
      <td className="flex gap-3 items-center justify-end  p-2">
        <BtnRounded icon={faCheck} color="green" onClick={() => setOpenModalApro(true)} id={data.id} />
        <BtnRounded   color="red"   onClick={() => setOpenModalRem(true)}   id={data.id} />
        <Modalconfirm
          open={openModalRem}
          setOpen={setOpenModalRem}
          selected={data.id}
          title={`Eliminar ${data.type == "A" ? "Actividad": "Anuncio"}`}
          msg={`Estas eliminando  "${data.titulo}", desea guardar el cambio?`}
          onConfirm={deleteSlope}
          loading={loadingRem}
        />

        <Modalconfirm
          open={openModalApro}
          setOpen={setOpenModalApro}
          selected={data.id}
          title={`Aprobar ${data.type == "A" ? "Actividad": "Anuncio"}`}
          msg={`Estas aprobando "${data.titulo}", desea guardar el cambio?`}
          onConfirm={aprobar}
          loading={loadingApro}
        />
      </td>
    </tr>
  );
};

export default Aprobacion;
