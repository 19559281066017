import { authType } from '../types/authType';

export const login = (user) => ({
  type: authType.authLogin,
  payload: user,
});

export const logout = () => ({
  type: authType.authLogout
})

export const authAppReady = () => ({
  type: authType.authAppReady
})

export const authAppNotReady = () => ({
  type: authType.authAppNotReady
})
