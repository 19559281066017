import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { updateMsg } from '../store/actions/dashboardActions';
import { fetchConToken } from '../utils/fetch';
import Loading from './Loading';
import BtnNormal from './UI/BtnNormal'

const MsgApp = ({data}) => {
    const dispatch = useDispatch();
    const [msg, setMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('none')
    
    const updateMessage = ()=>{
        setLoading(true)
        fetchConToken('msg/'+data?.id,{message:msg},"PUT")
        .then(r=>r.json())
        .then(({message})=>{
            dispatch(updateMsg(message))
            setLoading(false)
            handleSuccess()
        })
    }
    const handleSuccess = ()=>{
        setSuccess('block')
        setTimeout(() => {
            setSuccess('none')
        }, 1000);
    }
    useEffect(()=>{setMsg(data?.message)},[])

    return (
        <div className='my-5 w-full text-gray-600'>
            <h3 className='text-3xl text-center my-5 dark:text-gray-200'>Mensaje de pantalla principal en la app</h3>
            <div className='bg-white p-5 flex justify-center flex-col gap-3 items-center dark:bg-gray-300 w-full min-h-[200px] rounded-3xl shadow-3xl'>
                <p style={{color:'green', display: success}}>Mensaje actualizado!</p>
                <textarea onChange={(e)=>setMsg(e.target.value)} defaultValue={data?.message} className='w-9/12 self-center rounded-3xl outline-none p-3 text-center bg-gray-200 dark:bg-gray-400' rows="3"></textarea>
                <BtnNormal title={'Guardar'} onClick={updateMessage} />
                <Loading open={loading} title={"Actualizando mensaje"} />
            </div>
        </div>
    )
}

export default MsgApp
