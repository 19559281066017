import React from "react";
import DarkMode from "./DarkMode";

const NavTop = () => {

  return (
    <div className="justify-between flex w-full items-center">
      <p className="font-bold text-3xl">CRM DE IMAC JEREMIAS 616</p>

        <DarkMode />
      <img alt="logo"
        className="w-[100px]  dark:filter dark:invert dark:brightness-0"
        src={require(`../assets/img/logo-jere.png`)}
      />
    </div>
  );
};

export default NavTop;
