import { modalType } from "../types/modalType";


const initialState = {
    visible: false
}

const modalReducer = (state= initialState, action) => {
    
    switch (action.type) {
        case modalType.modalShow:
            return {visible:true};

        case modalType.modalHide:
            return {visible:false};

        default:
            return state;
    }
  }

export default modalReducer;