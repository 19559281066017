import React from "react";

const InputFile = ({file, setFile, afiche = null}) => {

  return (
    <div className="flex justify-center">
      <div className="rounded-full  lg:w-1/2">
        <div className="m-4">
          <div className="flex items-center justify-center w-full">
            <label className="flex flex-col w-full  cursor-pointer ">
              <div className="flex flex-col items-center justify-center ">

                {file ? 
                <a href={URL.createObjectURL(file)} target='_bank'><img src={URL.createObjectURL(file)} alt="profile" className={`${afiche ? 'rounded w-[400px] max-w-none h-[200px] object-cover':'rounded-full w-[100px] h-[100px]'}`} /></a>
                :
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                    clipRule="evenodd"
                  />
                </svg>}

                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                  Seleccione imagen
                </p>
              </div>
              <input type="file" accept="image/jpeg,image/png" onChange={(e)=>setFile(e.target.files[0])} style={{display:'none'}} />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputFile;
