import React from "react";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal from "./Modal";

const Modalconfirm = ({open, setOpen, title, msg, onConfirm, loading}) => {
  return (
    <Modal
      title="Confirmación requerida"
      open={open}
      onSubmit={onConfirm}
      loanding={loading}
      handleClose={setOpen}
    >
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <FontAwesomeIcon icon={faExclamation} color="red" />
            </div>
            <div className="mt-3 dark:text-white  ml-4">
                {title}
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {msg}
                </p>
              </div>
        </div>
      </div>
    </Modal>
  );
};

export default Modalconfirm;
