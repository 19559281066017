import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faExclamation, faSearch } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "moment/locale/es-do";

import UserModal from "./UserModal";
import BtnRounded from "./UI/BtnRounded";
import Modalconfirm from "./Modalconfirm";
import { fetchConToken } from "../utils/fetch";
import { removeMember } from "../store/actions/memberActions";

const Miembro = ({ member }) => {
  const dispatch = useDispatch();
  const { list } = useSelector(({ members }) => members);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);

  const remove = async () => {
    setLoadingRemove(true);
    try {
      const result = await fetchConToken(
        `auth/${member.id}`,
        {},
        "delete"
      ).then((r) => r.json());
      result.ok && dispatch(removeMember(member.id));
      setLoadingRemove(false);
      setOpenModal2(false);
    } catch (error) {
      alert("No se pudo completar el proceso, por favor intente mas tarde.");
      setLoadingRemove(false);
      console.log(error);
      setOpenModal2(false);
    }
  };

  const resetPassword = async () => {
    setLoadingReset(true);
    try {
      await fetchConToken(`auth/reset/${member.id}`, {}).then((r) => r.json());

      setLoadingReset(false);
      setOpenModal3(false);
    } catch (error) {
      alert("No se pudo completar el proceso, por favor intente mas tarde.");
      setLoadingReset(false);
      console.log(error);
      setOpenModal3(false);
    }
  };

  return (
    <tr className="bg-gray-100 my-3 border-b-2 border-b-white dark:border-b-gray-300 dark:bg-gray-200">
      <td className="p-2">
        <img
          alt="profile"
          className="w-16 rounded-full min-w-[50px]"
          src={`${window.location.protocol}//${member.foto}`}
        />
      </td>
      <td className="p-2">{member.name}</td>
      <td className="p-2">{member.email}</td>
      <td className="p-2">{member.rol}</td>
      <td className="p-2">
        {moment(member.createdAt).format("DD-MM-YYYY hh:mm:ss A")}
      </td>
      <td className="flex gap-3 items-center justify-end  p-2 min-h-[75px]">
        <UserModal
          open={openModal}
          setOpen={setOpenModal}
          selected={member.id}
        />
        <BtnRounded icon={faSearch} color="green" onClick={() => setOpenModal(true)} id={member.id} />
        <BtnRounded icon={faExclamation} color="green" onClick={() => setOpenModal3(true)} id={member.id} />
        <BtnRounded   color="red"   onClick={() => setOpenModal2(true)}   id={member.id} />
        <Modalconfirm
          open={openModal2}
          setOpen={setOpenModal2}
          selected={member.id}
          title="Eliminar miembro"
          msg={`Estas borrando a ${
            list?.filter((m) => m.id === member.id)[0]?.name
          }, segur@ que quieres guardar los cambios?`}
          onConfirm={remove}
          loading={loadingRemove}
        />
        <Modalconfirm
          open={openModal3}
          setOpen={setOpenModal3}
          selected={member.id}
          title="Resetear contraseña"
          msg={`Estas modificando la cuenta de ${
            list?.filter((m) => m.id === member.id)[0]?.name
          }, para poner la clave 1234, desea guardar el cambio?`}
          onConfirm={resetPassword}
          loading={loadingReset}
        />
      </td>
    </tr>
  );
};

export default Miembro;
