import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const Card = ({color, title, value, icon}) => {
    const [_color,setColor] = useState('');

    useEffect(() => {
        if (color === 'red') {
            setColor('from-red-500 to-red-900');
        }else if(color === 'orange'){
            setColor('from-[#D37919] to-[#fa880e]');
        }else if (color === 'blue') {
            setColor('from-[#2059A2] to-[#154694]')
        }
    }, [color]);


  return (
    <div className={`w-full bg-gradient-to-t  ${_color}  gap-2 min-h-[200px] rounded-3xl flex flex-col justify-center items-center shadow-2xl`}>
      <FontAwesomeIcon icon={icon} className="text-5xl text-white" />
      <p className="text-white">{title}</p>
      <p className="text-5xl text-white">{value}</p>
    </div>
  );
};

export default Card;
