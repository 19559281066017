import { anuncioType } from '../types/anuncioType';

export const selectAnuncio = (anuncioId) => ({
  type: anuncioType.select,
  payload: {anuncioId},
});

export const addAnuncio = (anuncio) => ({
  type: anuncioType.add,
  payload: anuncio,
});

export const updateAnuncio = (anuncio) => ({
  type: anuncioType.update,
  payload: anuncio,
});

export const removeAnuncio = (id) => ({
  type: anuncioType.remove,
  payload: { id },
});

export const loadAnuncios = (anuncios) => ({
  type: anuncioType.load,
  payload: anuncios,
});