import { navbarType } from "../types/navbarType"

const initialState = {
  showNavbar: true
}


const navbarReducer = (state= initialState, action) => {
    switch (action.type) {
        case navbarType.show:
            return {
              showNavbar: true
            }
        case navbarType.hide:
          return{
            showNavbar: false
          }
    
        default:
            return state;
    }
  }


export default navbarReducer;