import React, { useEffect, useState } from "react";
import { Link, useRoute } from "wouter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const BotonSidebar = ({ icon, title, to = "", onClick = null }) => {
  const [bg, setBg] = useState("");

  // `match` is boolean
  const [match] = useRoute(to);

  useEffect(() => {
    match ? setBg("btn-gradient-actived") : setBg("");
  }, [match]);

  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={` 
        text-[#C62626]  flex my-5 justify-around w-full items-center`}
      >
        <p>{title}</p>
      </button>
    );
  }

  return (
    <Link
      to={to}
      onClick={onClick}
      className={` bg-white 
                   
                   dark:hover:text-white 
                   hover:text-white 
                   hover:bg-gradient-to-t   
                   hover:from-[#C62626] 
                   hover:to-[#F74350] 
                   transition duration-300 
                   flex px-5 items-center 
                   justify-between 
                   h-[60px] shadow-xl  rounded-2xl   my-2  ${
                     bg ? bg : "dark:text-gray-500"
                   }`}
    >
      <FontAwesomeIcon icon={icon} />
      <p>{title}</p>
      <FontAwesomeIcon icon={faArrowRight} />
    </Link>
  );
};

export default BotonSidebar;
