import React from 'react';
import { useDispatch } from 'react-redux';
import { faBell, faBullhorn, faChurch, faHome, faSignOutAlt, faTasks, faUsers } from "@fortawesome/free-solid-svg-icons";

import BotonSidebar from './UI/BotonSidebar';
import { logout } from '../store/actions/authActions';

const NavPrivate = () => {
    const dispatch = useDispatch();

    const handleLogout = ()=>{
        dispatch(logout());
    }
    return (
        <nav className="my-5 gap-4">
        <BotonSidebar to='/admin/inicio' title='Inicio'  icon={faHome} />
        <BotonSidebar to='/admin/miembros' title='Miembros' icon={faUsers} />
        <BotonSidebar to='/admin/notificaciones' title='Notificaciones' icon={faBell} />
        <BotonSidebar to='/admin/actividades' title='Actividades' icon={faTasks} />
        <BotonSidebar to='/admin/anuncios' title='Anuncios' icon={faBullhorn} />
        <BotonSidebar to='/admin/horiario' title='Horario' icon={faChurch} />
        <BotonSidebar title='Cerrar sesion' icon={faSignOutAlt} onClick={handleLogout} />
      </nav>
    )
}

export default NavPrivate
