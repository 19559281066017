import React, {  useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Activity from "../../components/Activity";
import ActivityModal from "../../components/ActivityModal";
import BtnNormal from "../../components/UI/BtnNormal";
import UserModal from "../../components/UserModal";
import { loadActivities } from "../../store/actions/activityActions";
import { fetchConToken } from "../../utils/fetch";

const ActivitiesView = () => {
  const dispatch = useDispatch();
  const { list } = useSelector(({ activities }) => activities);
  const [loading, setLoading] = useState(true);

  useEffect(() => loadData(), []);

  const loadData = async () => {
    try {
      const result = await fetchConToken("activity").then((r) => r.json());
      dispatch(loadActivities(result.activityList));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="my-5 w-full text-gray-600">
      <h3 className="text-3xl my-5 dark:text-gray-200">Lista de Actividades</h3>
      <div className="bg-white p-5 dark:bg-gray-300 w-full rounded-3xl shadow-3xl">
        <table
          className="w-full text-left min-h-[500px]"
          border="1"
          frame="hsides"
          rules="rows"
        >
          <thead className="text-gray-500">
            <tr>
              <th className="py-3">Titulo</th>
              <th>Hora</th>
              <th>Fecha</th>
              <th>Lugar</th>
              <th>Aprobado</th>
              <th className="text-right">Acción</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={6}>
                  <div className="animate-pulse mt-9 flex w-full justify-center text-gray-700">
                    <div className="animate-spin h-5 w-5 mr-3 text-red-500 bg-gray-700"></div>
                    Cargando...
                  </div>
                </td>
              </tr>
            ) : (
              list?.map((a) => <Activity key={a.id} activity={a} />)
            )}
          </tbody>
        </table>
        <div className="flex justify-end">

              <ActivityModal
                selected={null}
                button={
                  <BtnNormal
                    title={"Nueva actividad"}
                    className="m-5"
                    onClick={() => null}
                  />
                }
              />
    
        </div>
      </div>
    </div>
  );
};

export default ActivitiesView;
