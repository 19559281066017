import { scheduleType } from "../types/scheduleType"

const initialState = {
  list: [],
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case scheduleType.load:
      return {
        ...state,
        list: action.payload,
      };
    case scheduleType.add:
      return {
        ...state,
        list: [action.payload, ...state.list],
      };
      case scheduleType.update:
        return {
          ...state,
          list: state.list.map(i =>i.id === action.payload.id ? action.payload : i),
        };
    case scheduleType.remove:
      return {
        ...state,
        list: state.list.filter((e) => e.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export default scheduleReducer;
