import React, { useEffect, useState } from "react";
import { checkDarkMode } from "../utils/checkDarkMode";

const DarkMode = () => {
  const [dark, setDark] = useState(false);
  const [cover, setCover] = useState("");
  const [toggle, setToggle] = useState("");

  useEffect(() => {
    checkDarkMode() ? setDark(true) : setDark(false);

    if (dark) {
      setCover("bg-blue-700");
      setToggle("translate-x-7");
    } else {
      setCover("bg-gray-500");
      setToggle("translate-x-0");
    }
  });

  const handleDarkMode = () => {
    document.documentElement.classList.toggle("dark");
    if (checkDarkMode()) {
      setDark(true);
      localStorage.setItem("darkMode", 1);
    } else {
      setDark(false);
      localStorage.setItem("darkMode", 0);
    }
  };

  return (
    <div
      className="flex justify-center items-center mt-4 cursor-pointer"
      onClick={handleDarkMode}
    >
      <span className="">
        <svg
          className="h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
          />
        </svg>
      </span>
      <div
        className={`w-14 h-7 flex items-center  rounded-full mx-3 px-1 transition-all ${cover}`}
      >
        <div
          className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-all ${toggle}`}
        ></div>
      </div>
      <span className="">
        <svg
          className="h-6 w-6 text-gray-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
          />
        </svg>
      </span>
    </div>
  );
};

export default DarkMode;
