import React from "react";
import { Route, Switch } from "wouter";
import LoginView from "./LoginView";

const LayoutPublic = () => {
  return (
    <div className="bg-slate-900 text-white flex items-center h-screen justify-center">
      <Switch>
        <Route path="/" component={LoginView} />
      </Switch>
    </div>
  );
};

export default LayoutPublic;
