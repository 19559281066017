import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  faSearch } from "@fortawesome/free-solid-svg-icons";

import BtnRounded from "./UI/BtnRounded";
import Modalconfirm from "./Modalconfirm";
import { fetchConToken } from "../utils/fetch";
import { removeSchedule } from "../store/actions/scheduleActions";
import ScheduleModal from "./ScheduleModal";

const Schedule = ({ schedule }) => {
  const dispatch = useDispatch();
  const { list } = useSelector(({ schedules }) => schedules);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);

  const remove = async () => {
    setLoadingRemove(true);
    try {
      const result = await fetchConToken(
        `schedule/${schedule.id}`,
        {},
        "delete"
      ).then((r) => r.json());
      result.ok && dispatch(removeSchedule(schedule.id));
      setLoadingRemove(false);
      setOpenModal2(false);
    } catch (error) {
      alert("No se pudo completar el proceso, por favor intente mas tarde.");
      setLoadingRemove(false);
      console.log(error);
      setOpenModal2(false);
    }
  };

  return (
    <tr className="bg-gray-100 my-3 border-b-2 border-b-white dark:border-b-gray-300 dark:bg-gray-200">
      <td className="p-2">{schedule.day}</td>
      <td className="p-2">{schedule.activity}</td>
      <td className="flex gap-3 items-center justify-end  p-2 min-h-[75px]">
        <ScheduleModal
          open={openModal}
          setOpen={setOpenModal}
          selected={schedule.id}
        />
        <BtnRounded icon={faSearch} color="green" onClick={() => setOpenModal(true)} id={schedule.id} />
        {/* <BtnRounded   color="red"   onClick={() => setOpenModal2(true)}   id={schedule.id} /> */}
        <Modalconfirm
          open={openModal2}
          setOpen={setOpenModal2}
          selected={schedule.id}
          title="Eliminar schedule"
          msg={`Estas borrando el schedule "${
            list?.filter((m) => m.id === schedule.id)[0]?.day
          }", segur@ que deseas efectual este cambio?`}
          onConfirm={remove}
          loading={loadingRemove}
        />
      </td>
    </tr>
  );
};

export default Schedule;
