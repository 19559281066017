const baseUrl = "https://j616.technologies.com.do/api";

const fetchSinToken = (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`;
  if (method === "GET") {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchConToken = async (
  endpoint,
  data,
  method = "GET",
  multiPart = false
) => {
  const url = `${baseUrl}/${endpoint}`;

  const token = localStorage.getItem("token");

  const form = new FormData();

  for (let key in data) {
    form.append(key, data[key]);
  }

  if (method === "GET") {
    return fetch(url, {
      method,
      headers: {
        "x-token": token,
      },
    });
  } else if (multiPart) {
    return fetch(url, {
      method,
      headers: {
        "x-token": token,
      },
      body: form,
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify(data),
    });
  }
};

export { fetchSinToken, fetchConToken };
