import React from "react";
import Aprobacion from "./Aprobacion";

const Aprobaciones = ({data}) => {
  return (
    <div className="my-5 w-full text-gray-600">
      <h3 className="text-3xl text-center my-5 dark:text-gray-200">
        Aprobaciones pendientes
      </h3>
      <div className="bg-white p-5 dark:bg-gray-300 w-full min-h-[200px] rounded-3xl shadow-3xl">
        <table
          className="w-full text-left"
          border="1"
          frame="hsides"
          rules="rows"
        >
          <thead className="text-gray-500">
            <tr>
              <td className="py-3">Titulo</td>
              <td>Tipo</td>
              <td>Comentario (privado)</td>
              <td className="text-right">Acción</td>
            </tr>
          </thead>
          <tbody>
            {
              data?.map(r=>(
                <Aprobacion key={r.titulo + r.id} data={r} />
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Aprobaciones;
