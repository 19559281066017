import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useForm from "../hooks/useForm";
import { updateSchedule, addSchedule } from "../store/actions/scheduleActions";
import { fetchConToken } from "../utils/fetch";
import Modal from "./Modal";
import Input from "./UI/forms/Input";
import InputFile from "./UI/forms/InputFile";
import Select from "./UI/forms/Select";

const initialState = {
  titulo: "",
  hora: "",
  fecha: 1,
  lugar: "",
  comentario: "",
  comentarioSolicitud: "",
  aprobado: 0,
};

const ScheduleModal = ({
  open = null,
  setOpen = null,
  selected,
  button = null,
}) => {
  const [open2, setOpen2] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [form, inputChange, setForm] = useForm(initialState);
  const {
    day,
    activity,
  } = form;

  const loadData = useCallback(async () => {
    try {
      const result = await fetchConToken("schedule/" + selected).then(r =>r.json());
      setForm(result.schedule);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const sendData = () => {
    setLoading(true);
    try {
      delete form.afiche;

      if (selected) {
        fetchConToken(
          `schedule/${selected}`,
          form,
          "post",
          true
        ).then((r) => r.json())
        .then(result =>{
          result.ok ? update(result.schedule) : errors();
        })

      } else {
        fetchConToken(
          "schedule",
          { ...form },
          "post",
          true
        ).then((r) => r.json())
        .then(result =>{
          result.ok ? register(result.schedule) : errors();
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const register = ({ id, titulo, hora, fecha, lugar, aprobado }) => {
    dispatch(
      addSchedule({ id, titulo, hora, fecha, lugar, aprobado })
    );
    handleClose();
  };

  const update = (data) => {
    dispatch(updateSchedule(data));
    handleClose();
  };

  const handleClose = () => {
    setLoading(false);
    setForm(initialState);
    open !== null ? setOpen(false) : setOpen2(false);
  };

  const errors = () => {};

  useEffect(() => {
    if (selected && open === true) {
      setLoading(true);
      loadData();
    } else {
      setLoading(false);
    }
  }, [open]);

  return (
    <>
      <Modal
        open={open ? open : open2}
        setOpen={setOpen}
        title={selected ? "Modificar horario" : "Agregar horario"}
        onSubmit={sendData}
        loanding={loading}
        handleClose={handleClose}
      >
        <Input
          title="Día"
          value={day}
          name="day"
          onChange={inputChange}
        />
          <Input
            title="Actividad"
            value={activity}
            name="activity"
            onChange={inputChange}
          />
      </Modal>
      {button && <div onClick={() => setOpen2(true)}>{button}</div>}
    </>
  );
};

export default ScheduleModal;
