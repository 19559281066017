import { memberType } from '../types/memberType';

export const addMember = (member) => ({
  type: memberType.add,
  payload: member,
});

export const updateMember = (member) => ({
    type: memberType.update,
    payload: member,
  });

export const removeMember = (id) => ({
  type: memberType.remove,
  payload: {id}
})

export const loadMembers = (members) => ({
  type: memberType.load,
  payload: members
})
