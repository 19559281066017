import React from "react";

const BtnNormal = ({title, className ='', onClick = ()=>{}, disabled = false, type='button'}) => {
  return (
    <button type={type} disabled={disabled} onClick={onClick} className={`bg-gradient-to-l from-[#2058A1] to-[#286BD8] px-5 py-3 rounded-3xl text-white transition-all hover:scale-75 ${className}`}>
      {title}
    </button>
  );
};

export default BtnNormal;
