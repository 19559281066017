import React, { useState } from "react";
import { useSelector } from "react-redux";
import UserModal from "./UserModal";

const UserNav = () => {
  const {name, foto, uid} = useSelector(state => state.authReducer);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState(null);
  
  const handleOpen = ()=>{
    setOpenModal(true);
    setSelected(uid)
  }
  return (
    <div className="flex flex-col justify-center items-center text-black">
      <img
        alt="profile"
        className="w-16 rounded-full"
        src={window.location.protocol+'//'+foto}
      />
      <p>{name}</p>
      <button className="text-blue-400 text-xs" onClick={handleOpen}>Editar perfil</button>
      <UserModal
            open={openModal}
            setOpen={setOpenModal}
            selected={selected}
            setSelected={setSelected}
          />
    </div>
  );
};

export default UserNav;
