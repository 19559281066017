import React from "react";
import { Route } from "wouter";
import LayoutPrivate from "../views/private/LayoutPrivate";
import LayoutPublic from "../views/public/LayoutPublic";

const Navigation = () => {
  return (
    <div>
      <Route path="/" component={LayoutPublic} />
      <Route path="/admin/:layout" component={LayoutPrivate} />
    </div>
  );
};

export default Navigation;