import React from "react";

import NavPrivate from "./NavPrivate";
import UserNav from "./UserNav";

const Sidebar = () => {
  return (
    <div style={{width:'420px'}}>
    <section className="bg-white dark:bg-slate-300 p-3 rounded-t-[30px] mt-3  ml-3" 
    style={{position:'fixed', width:'300px', height:'100vh'}} >
      <UserNav />
      <NavPrivate />
    </section>
    </div>
  );
};

export default Sidebar;
