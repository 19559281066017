import {authType} from '../types/authType';

const initialState = {
  logged: false,
  checking: true,
  ready: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authType.authLogin:
      return {
        ...state,
        logged: true,
        checking: false,
        ...action.payload,
      };
    case authType.authLogout:
      return {
        ...state,
        logged: false,
        checking: false,
      };
    case authType.authAppReady:
      return {
        ...state,
        ready: true,
      };
    case authType.authAppNotReady:
      return {
        ...state,
        ready: false,
      };

    default:
      return state;
  }
};

export default authReducer;
