import { scheduleType } from "../types/scheduleType";

export const addSchedule = (Schedule) => ({
  type: scheduleType.add,
  payload: Schedule,
});

export const updateSchedule = (Schedule) => ({
  type: scheduleType.update,
  payload: Schedule,
});

export const removeSchedule = (id) => ({
  type: scheduleType.remove,
  payload: { id },
});

export const loadSchedules = (schedules) => ({
  type: scheduleType.load,
  payload: schedules,
});
