import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import useForm from "../hooks/useForm";
import { login } from "../store/actions/authActions";
import { addMember, updateMember } from "../store/actions/memberActions";
import { fetchConToken } from "../utils/fetch";
import Modal from "./Modal";
import Input from "./UI/forms/Input";
import InputFile from "./UI/forms/InputFile";
import Select from "./UI/forms/Select";

const initialState = {
  name: "",
  email: "",
  rol: 1,
  password: "",
};

const UserModal = ({ open=null, setOpen=null, selected, button=null }) => {

  const [open2, setOpen2] = useState(false);
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.authReducer);
  const [loading, setLoading] = useState(true);
  const [form, inputChange, setForm] = useForm(initialState);
  const [image, setImage] = useState();
  const { name, email, rol, password } = form;

  const loadData = async () => {
    try {
      const result = await fetchConToken("auth/" + selected).then((r) =>
        r.json()
      );

      setForm({
        ...form,
        ...result.member,
      });

      showImage(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = () => {
    setLoading(true);
    sendData();
  };

  const sendData = async () => {
    try {
      let result;

      delete form.foto;
      if (selected) {
        result = await fetchConToken(
          `auth/${selected}`,
          { ...form, image: image.name ? image : undefined },
          "post",
          true
        ).then((r) => r.json());
        result.ok ? update(result.user) : errors();
      } else {
        result = await fetchConToken(
          "auth/new",
          { ...form, image },
          "post",
          true
        ).then((r) => r.json());
        result.ok ? register(result) : errors();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const register = ({ uid, name, correo, foto, fecha_registro }) => {
    dispatch(
      addMember({
        id: uid,
        name,
        email: correo,
        foto,
        createdAt: fecha_registro,
      })
    );
    handleClose();
  };

  const update = (data) => {
    data.id == uid && dispatch(login(data));
    dispatch(updateMember(data));
    handleClose();
  };

  const handleClose = () => {
    setForm(initialState);
    setImage(null);
    open !== null ? setOpen(false): setOpen2(false);
  };
  
  const showImage = (result) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", window.location.protocol + "//" + result.member.foto);
    xhr.responseType = "arraybuffer";

    xhr.onload = function (error) {
      var blob = new Blob([xhr.response]);
      blob = blob.slice(0, blob.size, "image/jpeg");
      setImage(blob);
    };
    xhr.send();
  };

  const errors = () => {};

  useEffect(() => {
    if (selected && open === true) {
      setLoading(true);
      loadData();
    } else {
      setLoading(false);
    }
  }, [open]);


  return (
    <>
    <Modal
      open={open ? open: open2}
      setOpen={setOpen}
      title={selected ? "Detalles de miembro": "Registrar miembro"}
      onSubmit={onSubmit}
      loanding={loading}
      handleClose={handleClose}
    >
      <InputFile file={image} setFile={setImage} />
      <Input title="Nombre" value={name} name="name" onChange={inputChange} />
      <Input
        title="Correo"
        value={email}
        name="email"
        onChange={inputChange}
        type="email"
      />
      {!selected && (
        <Input
          title="contraseña"
          value={password}
          name="password"
          au
          onChange={inputChange}
          type="password"
        />
      )}
      <Select
        options={[
          { key: 1, value: "Admin" },
          { key: 2, value: "Diácono" },
          { key: 3, value: "Presidente" },
          { key: 4, value: "Secretario" },
          { key: 5, value: "Miembro" },
        ]}
        title="Rol"
        name="rol"
        value={rol}
        onChange={inputChange}
      />
    </Modal>
    {button && <div onClick={()=>setOpen2(true)}>{button}</div>}
    </>
  );
};

export default UserModal;
