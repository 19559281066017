import React from "react";

const Select = ({title,name, value, options, onChange}) => {
  return (
    <div className="flex items-center mb-6 flex-col">
      <div className="mb-3">
        <label
          className="block text-gray-500 font-bold   pr-4 dark:text-white"
          htmlFor="inline-full-name"
        >
          {title}
        </label>
      </div>
      <div className="w-full">
      <div className="relative">
        <select name={name} onChange={onChange} value={value} className="block focus:border-blue-500 appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-3xl leading-tight focus:outline-none focus:bg-white">
         {options.map(({key, value})=>(
             <option key={key} value={key}>{value}</option>
         ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Select;
