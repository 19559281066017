import React from "react";

const Input = ({title, value, color=null, onChange=null, type='text', name, readOnly=false}) => {
  return (
    <div className="flex  items-center flex-col mb-6">
      <div className=" mb-3">
        <label
          className={`block  text-gray-500 font-bold   md:mb-0 pr-4 ${color ? color:'dark:text-white'}`}
          htmlFor="inline-full-name"
        >
          {title}
        </label>
      </div>
      <div className="w-full">
        <input
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-3xl w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
          value={value}
          onChange={onChange}
          type={type}
          name={name}
          readOnly={readOnly}
          autoComplete={'off'}
          step="2"
        />
      </div>
    </div>
  );
};

export default Input;
