import { faExclamation, faTasks, faUsers } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Aprobaciones from '../../components/Aprobaciones';
import Card from '../../components/Card';
import Loading from '../../components/Loading';
import MsgApp from '../../components/MsgApp';
import { loadDashboard } from '../../store/actions/dashboardActions';
import { fetchConToken } from '../../utils/fetch';

const DashboardView = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
    const { data } = useSelector(({ dashboard }) => dashboard);

    useEffect(()=>{
        fetchConToken('dashboard').then(r=>r.json()).then(data => {
            setLoading(false);
            dispatch(loadDashboard(data))
        })
    },[])

    return (
            <div className='content mt-10'>
                <div className='grid grid-cols-3 gap-7'>
                    <Card  title='Actividades del mes' value={data?.statistics.month} color='red' icon={faExclamation} />
                    <Card  title='Actividades del año actual' value={data?.statistics.year} color='orange' icon={faTasks} />
                    <Card  title='Miembros registrados' value={data?.statistics.members} color='blue' icon={faUsers} />
                </div>
                <Aprobaciones data={data?.slopes} />
                <MsgApp data={data?.msg} />
                <Loading open={loading} title={'Espere por favor'}/>
            </div>
    )
}

export default DashboardView;
