import { activityType } from "../types/activityType";

export const addActivity = (activity) => ({
  type: activityType.add,
  payload: activity,
});

export const updateActivity = (activity) => ({
  type: activityType.update,
  payload: activity,
});

export const removeActivity = (id) => ({
  type: activityType.remove,
  payload: { id },
});

export const loadActivities = (activities) => ({
  type: activityType.load,
  payload: activities,
});
