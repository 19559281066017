import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useForm from "../hooks/useForm";
import { updateActivity, addActivity } from "../store/actions/activityActions";
import { fetchConToken } from "../utils/fetch";
import Modal from "./Modal";
import Input from "./UI/forms/Input";
import InputFile from "./UI/forms/InputFile";
import Select from "./UI/forms/Select";

const initialState = {
  titulo: "",
  hora: "",
  fecha: 1,
  lugar: "",
  comentario: "",
  comentarioSolicitud: "",
  aprobado: 0,
};

const ActivityModal = ({
  open = null,
  setOpen = null,
  selected,
  button = null,
}) => {
  const [open2, setOpen2] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [form, inputChange, setForm] = useForm(initialState);
  const [image, setImage] = useState();
  const {
    titulo,
    hora,
    fecha,
    lugar,
    comentario,
    comentarioSolicitud,
    aprobado,
  } = form;

  const loadData = useCallback(async () => {
    try {
      const result = await fetchConToken("activity/" + selected).then(r =>r.json());
      setForm(result.activity);
      showImage(result);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const sendData = () => {
    setLoading(true);
    try {
      delete form.afiche;

      if (selected) {
        fetchConToken(
          `activity/${selected}`,
          { ...form, image: image?.name ? image : undefined },
          "post",
          true
        ).then((r) => r.json())
        .then(result =>{
          result.ok ? update(result.activity) : errors();
        })

      } else {
        fetchConToken(
          "activity",
          { ...form, image },
          "post",
          true
        ).then((r) => r.json())
        .then(result =>{
          result.ok ? register(result.activity) : errors();
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const register = ({ id, titulo, hora, fecha, lugar, aprobado }) => {
    dispatch(
      addActivity({ id, titulo, hora, fecha, lugar, aprobado })
    );
    handleClose();
  };

  const update = (data) => {
    dispatch(updateActivity(data));
    handleClose();
  };

  const handleClose = () => {
    setLoading(false);
    setForm(initialState);
    setImage(null);
    open !== null ? setOpen(false) : setOpen2(false);
  };

  const showImage = (result) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", window.location.protocol + "//" + result.activity.afiche);
    xhr.responseType = "arraybuffer";

    xhr.onload = function (error) {
      var blob = new Blob([xhr.response]);
      blob = blob.slice(0, blob.size, "image/jpeg");
      setImage(blob);
    };
    xhr.send();
  };

  const errors = () => {};

  useEffect(() => {
    if (selected && open === true) {
      setLoading(true);
      loadData();
    } else {
      setLoading(false);
    }
  }, [open]);

  return (
    <>
      <Modal
        open={open ? open : open2}
        setOpen={setOpen}
        title={selected ? "Detalles actividad" : "Agregar actividad"}
        onSubmit={sendData}
        loanding={loading}
        handleClose={handleClose}
      >
        <InputFile afiche={true} file={image} setFile={setImage} />
        <Input
          title="Titulo"
          value={titulo}
          name="titulo"
          onChange={inputChange}
        />
        <div className="grid grid-cols-2 gap-1">
          <Input
            title="Hora (24h)"
            value={hora}
            name="hora"
            onChange={inputChange}
            type="time"
          />

          <Input
            title="Fecha"
            value={fecha}
            name="fecha"
            onChange={inputChange}
            type="date"
          />
        </div>
        <Input
          title="Lugar"
          value={lugar}
          name="lugar"
          onChange={inputChange}
        />
        <Input
          title="Comentario público"
          value={comentario}
          name="comentario"
          onChange={inputChange}
        />
        <Input
          title="Comentario para secretaria"
          value={comentarioSolicitud}
          name="comentarioSolicitud"
          onChange={inputChange}
        />
        <Select
          options={[
            { key: true, value: "Aprobado" },
            { key: false, value: "No aprobado" },
          ]}
          title="Aprobado"
          name="aprobado"
          value={aprobado}
          onChange={inputChange}
        />
      </Modal>
      {button && <div onClick={() => setOpen2(true)}>{button}</div>}
    </>
  );
};

export default ActivityModal;
