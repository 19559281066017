import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "wouter";

import BtnNormal from "../../components/UI/BtnNormal";
import Input from "../../components/UI/forms/Input";
import useForm from "../../hooks/useForm";
import { login } from "../../store/actions/authActions";
import { fetchSinToken } from "../../utils/fetch";

const LoginView = ({ navigation }) => {
  const dispatch = useDispatch();
  const [, setLocation] = useLocation();
  const [loading, setLoading] = useState(false);
  const { logged } = useSelector((state) => state.authReducer);

  useEffect(() => {
    logged && setLocation("/admin/inicio");
  }, [logged]);

  const [form, setForm] = useForm({
    email: "",
    password: "",
  });

  const { email, password } = form;

  const handleSubmit = (e) => {
    e.preventDefault();
    senData();
  };

  const senData = async () => {
    try {
      setLoading(true);
      const res = await fetchSinToken("auth", form, "post").then((r) =>
        r.json()
      );
      setLoading(false);
      if (res.ok) {
        delete res.ok;
        dispatch(login({ ...res }));
        localStorage.setItem('token', res.token);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    console.log(error);
  };

  return (
    <div className="bg-gradient-to-tl from-blue-700 to-slate-900 text-white flex-col flex items-center h-screen justify-center w-screen">
      <h3 className="my-10 text-5xl ">Bienvenid@</h3>
      <div className="bg-gray-50 rounded-2xl shadow-2xl h-2/3 w-6/12 grid md:grid-cols-2 ">
        <div className=" rounded-l-2xl md:flex items-center justify-center flex-col hidden">
          <h1 className="text-5xl text-center text-black">
            Portal administrativo
          </h1>
          <h3 className="my-3 text-black">IMAC jeremias 616</h3>
          <img src={require("../../assets/img/login.png")} alt="" />
        </div>
        <div className=" rounded-r-2xl  flex  justify-center flex-col md:border-l px-8 text-gray-700">
          <h3 className="text-center text-3xl font-bold text-gray-500 mb-10">
            Ingresa al portal
          </h3>
          <form onSubmit={handleSubmit}>
            <Input
              title={"Correo"}
              color={"dark:text-gray-500"}
              onChange={setForm}
              value={email}
              name={'email'}
            />
            <Input
              title={"Pass"}
              color={"dark:text-gray-500"}
              type="password"
              onChange={setForm}
              value={password}
              name={'password'}
            />
            <BtnNormal
              type="submit"
              title={loading ? "Cargando..." : "Ingresar"}
              className={`block w-full ${loading && "animate-pulse"}`}
              disabled={loading}
            />
            <a href="#" className="mt-4 text-center text-blue-600 block">
              Perdi mi acceso
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginView;
