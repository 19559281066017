import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "wouter";
import {useLocation} from "wouter";
import NavTop from "../../components/NavTop";
import Sidebar from "../../components/Sidebar";
import ActivitiesView from "./ActivitiesView";
import AnunciosView from "./AnunciosView";
import Dashboard from "./DashboardView";
import MiembrosView from "./MiembrosView";
import SchedulesView from "./SchedulesView";

const LayoutPrivate = () => {
    const {logged} = useSelector(state => state.authReducer);
    const [, setLocation] = useLocation();
    useEffect(() => {
        !logged && setLocation('/');
    }, [logged]);
  return (
    <div className="flex  bg-gray-200  dark:bg-slate-900 text-gray-500  dark:text-white min-h-screen  transition-colors duration-500">
      <Sidebar />
      <div className="content w-full p-5">
        <NavTop />
        <Switch>
          <Route path="/admin/inicio" component={Dashboard} />
          <Route path="/admin/miembros" component={MiembrosView} />
          <Route path="/admin/actividades" component={ActivitiesView} />
          <Route path="/admin/anuncios" component={AnunciosView} />
          <Route path="/admin/horiario" component={SchedulesView} />
        </Switch>
      </div>
    </div>
  );
};

export default LayoutPrivate;
